// intakeAgentUtils.js

const intakeAgentIDs = [
  "00000000-0000-0000-0000-000000000000",
  "11111111-1111-1111-1111-111111111111",
  "22222222-2222-2222-2222-222222222222",
];

const getRandomIntakeAgentID = () => {
  return intakeAgentIDs[Math.floor(Math.random() * intakeAgentIDs.length)];
};

export { getRandomIntakeAgentID };
