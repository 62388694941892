import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";

const MSMEParticipant = () => {
  const navigate = useNavigate();

  const handleParticipantClick = () => {
    navigate("/registration-form");
  };

  const handleMSMEClick = () => {
    navigate("/msme-onboarding");
  };
  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Header>Welcome!</Header>
        
          <Ptag>
            If you are a Youth Participant, click on Youth Participant.
          </Ptag>
          <Ptag>
            If you are representing an MSME, click on MSME.
          </Ptag>
          <ButtonWrapper>
            <ParticipantButton onClick={handleParticipantClick}>
             Youth Participant
            </ParticipantButton>
            <MSMEButton onClick={handleMSMEClick}>MSMEs </MSMEButton>
          </ButtonWrapper>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default MSMEParticipant;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    /* min-height: 90vh !important; */
  }
`;
const ContentWrapper = styled.div`
  background-color: ${colors.GRAY};
  max-width: 600px !important;
  min-width: 600px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 40vh !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 100% !important;
    min-width: 80% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    padding: 1rem;
    border-radius: 24px;
    max-width: 100% !important;
    min-width: 80% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    align-items: start;
    min-width: 80% !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.extraLarge};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: center;
  color: ${colors.DARK_BROWN};
  margin: 0 !important;
  padding: 0 !important;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.small};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.large};
    line-height: 0px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 0px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 0px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${colors.DEEP_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};

    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px !important;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const MSMEButton = styled.button`
  background: ${colors.ORANGE};
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.ORANGE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  color: ${colors.WHITE};
  font-size: ${fontSizes.small};
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }
`;

const ParticipantButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const H6Tag = styled.h6`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.large};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;

const PBody = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;

const PBodyParent = styled(PBody)`
  padding-top: 0.5rem;
`;

const EclipseDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 0.5rem;
`;
