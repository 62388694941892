import React, { useState } from "react";
import Page1 from "../MSMES/Page1";
import SubmitMsme from "../MSMES/Submit";
import Page2 from "../MSMES/Page2";
import Page3 from "../MSMES/Page3";
import Page4 from "../MSMES/Page4";
import emailjs from "@emailjs/browser";

const OnboardingMsmes = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // Initialize with empty values for each form field
    firstName: "",
    lastName: "",
    ageRange: "",
    gender: "",
    phone: "",
    phonewhatsapp: "",
    email: "",
    meansOfId: "",
    educationCompleted: "",
    otherEduation: "",
    cooperativeMember: "",
    specifyCooperative: "",
    businessName: "",
    businessPhone: "",
    businessemail: "",
    businessAddress: "",
    businessInto: "",
    businessSpeciality: "",
    businessOperating: "",
    businessType: "",
    businessCaetgory: "",
    apprentices: "",
    specifyhowmany: "",
    moreApprentices: "",
    specifyhowManyMoreApprentices: "",
    postTraining: "",
    averageTurnover: "",
    terms_and_conditions_msme: "",
  });

  // Function to handle navigation to the next step
  const nextStep = () => {
    setStep(step + 1);
  };
  // Function to handle navigation to previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Render different form components based on the current step
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Page1
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <Page2
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <Page3
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <Page4
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <SubmitMsme
            prevStep={prevStep}
            formData={formData}
            submitData={submitData}
            setFormData={setFormData}
          />
        );
      default:
        return <div>No form step found</div>;
    }
  };

  const submitData = async () => {
    // console.log(formData, "formdata");

    const apiUrl = process.env.REACT_APP_MSMES_GOOGLE_SHEET_API_URL;
    // console.log(apiUrl, "API URL")

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([[...Object.values(formData)]]),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok ");
      }
      const res = await response.json();
      console.log(res, "data reaponse");

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          user_email: formData.email,
          user_name: formData.firstName,
          // Add other template parameters if needed
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      // Reset form data after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        ageRange: "",
        gender: "",
        phone: "",
        phonewhatsapp: "",
        email: "",
        meansOfId: "",
        educationCompleted: "",
        otherEduation: "",
        cooperativeMember: "",
        specifyCooperative: "",
        businessName: "",
        businessPhone: "",
        businessemail: "",
        businessAddress: "",
        businessInto: "",
        businessSpeciality: "",
        businessOperating: "",
        businessType: "",
        businessCaetgory: "",
        apprentices: "",
        specifyhowmany: "",
        moreApprentices: "",
        specifyhowManyMoreApprentices: "",
        postTraining: "",
        averageTurnover: "",
        terms_and_conditions_msme: "",
      });
      // Navigate to the next step or perform other actions
    } catch (err) {
      // Handle errors
    }
  };
  return (
    <div>
      {" "}
      <div>{renderStep()}</div>
    </div>
  );
};

export default OnboardingMsmes;
