/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Indicator from "../StepIndicator/Indicator";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Select from "react-select";



const Page1 = ({ nextStep, prevStep, formData, setFormData }) => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };
  const [errors, setErrors] = useState({});


  const options = [
    { value: "facebook", label: "Facebook" },
    { value: "whatsapp", label: "Whatsapp" },
    { value: "telegram", label: "Telegram" },
    { value: "instagram", label: "Instagram" },
    { value: "twitter", label: "Twitter" },
    { value: "tiktok", label: "Tiktok" },
    { value: "others", label: "Others" },
  ];


  useEffect(() => {
    if (!formData.oftenUsedApp) {
      setFormData({
        ...formData,
        oftenUsedApp: [],
      });
    }
  }, [formData, setFormData]);

  // const handleSelectChange = (selectedOptions) => {
  //   setFormData({
  //     ...formData,
  //     oftenUsedApp: selectedOptions.map((option) => option.value),
  //   });
  // };

  const handleSelectChange = (selectedOptions) => {
  
    setFormData({
      ...formData,
      oftenUsedApp: selectedOptions ? selectedOptions.map((option) => option.value) : [],
    });
  };

 
  
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: `1px solid ${colors.LIGHT_GRAY}`,
      background: `${colors.DEEP_GRAY}`,
      color: `${colors.DEEPER_GRAY}`,
      fontFamily: `${fonts.FONT_FAMILY_BODY}`,
      fontSize: `${fontSizes.extraSmall}`,
      fontStyle: "normal",
      fontWeight: 400,
      marginTop: "0.5rem",
      width: "100%",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0, 
      maxWidth: "95%", 
      overflowX: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", 
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `${colors.PRIMARY}` : `${colors.DEEP_GRAY}`,
      color: state.isSelected ? `${colors.WHITE}` : `${colors.DEEPER_GRAY}`,
      padding: "2px 2px 2px 12px", 
      fontFamily: `${fonts.FONT_FAMILY_BODY}`,
      fontSize: `${fontSizes.extraSmall}`,
      cursor: 'pointer',
    ':hover': {
      backgroundColor: '#0056b3', 
      color: `${colors.WHITE}`,
    },
    }),
    multiValue: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
    }),
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.educationCompleted)
      newErrors.educationCompleted = "Education Completed is required";
    if (!formData.otherEduation && formData.educationCompleted === "others")
      newErrors.otherEduation = "Other Education is required";
    if (!formData.employmentStatus)
      newErrors.employmentStatus = "Employment Status is required";
    if (!formData.currentWork)
      newErrors.currentWork = "Current Work is required";
    if (!formData.earningsPerMonth)
      newErrors.earningsPerMonth = "Monthly Earnings is required";
    if (!formData.smartPhone)
      newErrors.smartPhone = "Smartphone status is required";
    // if (formData.oftenUsedApp.length === 0)
    //   newErrors.oftenUsedApp = "Often used app is required";
    // if (formData.oftenUsedApp.includes("others") && !formData.pleaseSpecify)
    //   newErrors.pleaseSpecify = "Please specify is required";
    if ((formData.oftenUsedApp || []).length === 0)
      newErrors.oftenUsedApp = "Often used app is required";
    if ((formData.oftenUsedApp || []).includes("others") && !formData.pleaseSpecify)
      newErrors.pleaseSpecify = "Please specify is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Background Experience</Header>
            <Indicator currentStep={4} totalSteps={6} />
            <Ptag>
              Let’s get to know you enter your accurate Background & Experience
              information for the program.
            </Ptag>

            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>

          <StyledForm>
            <StyledChild>
              <StyledLabel htmlFor="educationCompleted">
                Highest Level of Education Completed
              </StyledLabel>
              <StyledSelect
                id="educationCompleted"
                name="educationCompleted"
                defaultValue={formData.educationCompleted}  
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    educationCompleted: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="noFormalEducation">No formal education</option>
                <option value="primary">Primary education</option>
                <option value="secondary">
                  Secondary education (e.g., O'Levels, SSCE, NECO)
                </option>
                <option value="undergraduate">
                  Undergraduate (e.g Degree in view)
                </option>
                <option value="vocational">
                  Vocational/Technical education (e.g., NTC, NABTEB)
                </option>
                <option value="tertiary">
                  Tertiary education (e.g., NCE, ND, HND, BSc, BA, BTech)
                </option>
                <option value="postgraduate">
                  Postgraduate education (e.g., PGD, MSc, MA, MBA, PhD)
                </option>
                <option value="others">
                  Other Education
                </option>
              </StyledSelect>
              {errors.educationCompleted && (
                <ErrorMessage>{errors.educationCompleted}</ErrorMessage>
              )}
            </StyledChild>
            {formData.educationCompleted === "others" && (
            <StyledChild>
              <StyledLabel htmlFor="otherEduation">
                Other Educational Qualifications (if applicable)
              </StyledLabel>
              <StyledInput
                type="text"
                id="otherEduation"
                name="otherEduation"
                required
                defaultValue={formData.otherEduation}
                onChange={(e) =>
                  setFormData({ ...formData, otherEduation: e.target.value })
                }
              />
              {errors.otherEduation && (
                <ErrorMessage>{errors.otherEduation}</ErrorMessage>
              )}
            </StyledChild>
            )}
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="employmentStatus">
                  Employment status
                </StyledLabel>
                <StyledSelect
                  id="employmentStatus"
                  name="employmentStatus"
                  defaultValue={formData.employmentStatus}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      employmentStatus: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="unemployed">unemployed</option>
                  <option value="employed">self employed</option>
                  <option value="unemployed">employed</option>
                  <option value="employed">student</option>
                </StyledSelect>
                {errors.employmentStatus && (
                  <ErrorMessage>{errors.employmentStatus}</ErrorMessage>
                )}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="currentWork">
                  What do you currently do ?
                </StyledLabel>
                <StyledInput
                  type="text"
                  id="currentWork"
                  name="currentWork"
                  required
                  defaultValue={formData.currentWork}
                  onChange={(e) =>
                    setFormData({ ...formData, currentWork: e.target.value })
                  }
                />
                {errors.currentWork && (
                  <ErrorMessage>{errors.currentWork}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <StyledChild>
              <StyledLabel htmlFor="earningsPerMonth">
                How much do you earn per month?
              </StyledLabel>
              <StyledSelect
                id="earningsPerMonth"
                name="earningsPerMonth"
                defaultValue={formData.earningsPerMonth}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    earningsPerMonth: e.target.value,
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="0-30000">0 - N30,000</option>
                <option value="31000-750000">N31,000 - N75,0000</option>
                <option value="760000-150000">N76,0000 - N150,000</option>
                <option value="151000-300000">N151,000 - N300,000</option>
                <option value="300000-above">N300,000 and above</option>
              </StyledSelect>
              {errors.earningsPerMonth && (
                <ErrorMessage>{errors.earningsPerMonth}</ErrorMessage>
              )}
            </StyledChild>

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="smartPhone">
                  Do you have a smartphone
                </StyledLabel>
                <StyledSelect
                  id="smartPhone"
                  name="smartPhone"
                  defaultValue={formData.smartPhone}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      smartPhone: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </StyledSelect>
                {errors.smartPhone && (
                  <ErrorMessage>{errors.smartPhone}</ErrorMessage>
                )}
              </StyledChild>

              <StyledChild>
                <StyledLabel htmlFor="socialMedia">
                  Which App do you use often
                </StyledLabel>
                <Select
                  isMulti
                  name="oftenUsedApp"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={options.filter((option) =>
                    (formData.oftenUsedApp || []).includes(option.value)
                  )}
                  onChange={handleSelectChange}
                  placeholder="Choose all that apply"
                  styles={customStyles}
                />   
                {errors.oftenUsedApp && (
                  <ErrorMessage>{errors.oftenUsedApp}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            {(formData.oftenUsedApp || []).includes("others") && (
              <StyledChild>
                <StyledLabel htmlFor="pleaseSpecify">
                  If others, please specify
                </StyledLabel>
                <StyledInput
                  type="text"
                  id="pleaseSpecify"
                  name="pleaseSpecify"
                  defaultValue={formData.pleaseSpecify}
                  onChange={(e) =>
                    setFormData({ ...formData, pleaseSpecify: e.target.value })
                  }
                />
                {errors.pleaseSpecify && (
                  <ErrorMessage>{errors.pleaseSpecify}</ErrorMessage>
                )}
              </StyledChild>
            )}

            <ButtonWrapper>
              <StyledButton onClick={prevStep}> Previous</StyledButton>
              <StyledButton2 onClick={handleNextStep}> Next</StyledButton2>
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Page1;

const ErrorMessage = styled.span`
  color: red;   
  font-size: 12px;
  margin-top: 5px;
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 1rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px !important;
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;

  //Here for small screen updates
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 80% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6vh !important;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 5vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
