/* eslint-disable no-unused-vars */
import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";
import { Teamdata } from "./Page6-Team-Data";

const Page6 = () => {
  return (
    <Maindiv>
      <Header>Programme Team</Header>

      <Teamflex>
        {Teamdata?.map((d) => (
          <Childdiv key={d.id}>
            <img
              // src={d?.image}
              src={process.env.PUBLIC_URL + d.image}
              alt={d.name}
            />
            <div>
              <H6>{d?.name} </H6>
              <Paragraph>{d?.role} </Paragraph>
            </div>
          </Childdiv>
        ))}
      </Teamflex>
    </Maindiv>
  );
};

export default Page6;

const Maindiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 0 20px;
`;

const Header = styled.h1`
  color: ${colors.DARK_BROWN};
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: -0.04em;
  text-align: center;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
`;
const Teamflex = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 auto;
  max-width: 100%;

  @media only screen and (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Childdiv = styled.div`
  text-align: center;
  margin-top: 3rem;
`;

const H6 = styled.h6`
  color: ${colors.DARK_BROWN};
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.extraLarge};
  font-weight: 600;
  padding: 0;
  margin: 0;
`;

const Paragraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  color: ${colors.DARK_SHADE_BROWN};
  padding: 0;
  margin: 0;
`;
