export const Testimonial = [
  {
    id: 1,
    image: "/Envelop.svg",
    title: "Vocational skills training",
    body: "Automatically deposit a percentage of your daily spendings and watch your savings grow as you spend.",
  },
  {
    id: 2,
    image: "/Envelop.svg",
    title: "Paid apprenticeship",
    body: "Automatically deposit a percentage of your daily spendings and watch your savings grow as you spend.",
  },
  {
    id: 3,
    image: "/Envelop.svg",
    title: "Entrepreneurship & Business Skills",
    body: "Automatically deposit a percentage of your daily spendings and watch your savings grow as you spend.",
  },
  {
    id: 4,
    image: "/Envelop.svg",
    title: "Post training Starter Packs",
    body: "Automatically deposit a percentage of your daily spendings and watch your savings grow as you spend.",
  },
  {
    id: 5,
    image: "/Envelop.svg",
    title: "Access to Jobs & new Markets",
    body: "Automatically deposit a percentage of your daily spendings and watch your savings grow as you spend.",
  },
  {
    id: 6,
    image: "/Envelop.svg",
    title: "Skills Certification",
    body: "Automatically deposit a percentage of your daily spendings and watch your savings grow as you spend.",
  },
];
