/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { data } from "./Page2-Data";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import { responsive } from "../../util/Responsive-Carousel";

const Page2 = () => {
  return (
    <Maindiv>
      <Parentdiv>
        <Header>Interest areas in Garments, Footwear & Leather work</Header>
        {/* <Paragraph>
          Receive, send, exchange, and manage multiple currencies in one app.
          Open a foreign UK, US, and EU bank account from your phone for free.
        </Paragraph> */}
      </Parentdiv>
  
      <MainCarousel>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          arrows={false}
          infinite={true}
          responsive={responsive}
          ssr={false}
          autoPlay={true}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={1000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {data.map((d, index) => (
            <StyledCarousel key={index}>
              <CarouselImage
                // src={d.image}
                src={process.env.PUBLIC_URL + d.image}
                alt="images"
              />
            </StyledCarousel>
          ))}
        </Carousel>
      </MainCarousel>
    </Maindiv>
  );
};

export default Page2; 

const Maindiv = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const Parentdiv = styled.div`
  width: 50% !important;
  margin: 0 auto;
  padding: 0 20px;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    padding: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 70% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60% !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 60% !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 40% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 40% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]} !important;
  font-weight: 700;
  line-height: 60.4px;
  letter-spacing: -0.04em;
  text-align: center;
  color: ${colors.DARK_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.mediumLarge} !important;
    line-height: 50px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
`;

const MainCarousel = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px !important;
  @media only screen and (max-width: 420px) {
    padding: 0 100px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 0 100px !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 0 40px;
  }
`;

const StyledCarousel = styled.div`
  margin-top: 2rem;
`;

const CarouselImage = styled.img`
  height: 60vh;
  @media only screen and (max-width: 420px) {
    height: 25vh;
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 30vh;
    width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 25vh;
    width: 90%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 30vh !important;
    width: 90%;
  }
  @media only screen and (min-width: 922px) and (max-width: 1100px) {
    height: 35vh;
    width: 90%;
  }
  @media only screen and (min-width: 1101px) and (max-width: 1200px) {
    height: 37vh;
    width: 90%;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* height: 37vh; */
    width: 90%;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* height: 40vh; */
    width: 90%;
  }
`;
