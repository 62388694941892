import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";

const Page10 = () => {
  return (
    <TestimonialParent>
      {Testimonial.map((d) => (
        <TestimonialChild key={d.id}>
          <img src={d.image} alt={d.title} />
          <TesContainer>
            <TestimonailHeader> {d.title} </TestimonailHeader>
            <TesimonialParagraph> {d.body} </TesimonialParagraph>
          </TesContainer>
        </TestimonialChild>
      ))}
    </TestimonialParent>
  );
};

export default Page10;

const TestimonialChild = styled.div`  
  padding: 48px 32px 48px 32px;
  background: ${colors.LIGHT_BROWN};
  border: 1px solid ${colors.LIGHT_BROWN};
  border-radius: 12px;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TestimonialParent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  max-width: 100%;
  margin-top: 2rem;

  @media only screen and (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TesContainer = styled.div`
  padding-top: 1rem;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TestimonailHeader = styled.h6`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${colors.WHITE};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.small};
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.medium};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.medium};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TesimonialParagraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: ${colors.LIGHTER_BROWN};
  padding: 0;
  padding-top: 0.5rem !important;
  margin: 0;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

export const Testimonial = [
  {
    id: 1,
    image: "/Envelop.svg",
    title: "Cluster Development",
    body: "Upskill Fashion entrepreneurs and enhance business capacity through business development service providers and platforms",
  },
  {
    id: 2,
    image: "/Envelop.svg",
    title: "Local Economic development",
    body: "Facilitate linkages to markets and financial opportunities for the fashion clusters",
  },
  {
    id: 3,
    image: "/Envelop.svg",
    title: "Improved Business Environment",
    body: "Support advocacy to improve business policies, provide infrastructure and enhance the overall business environment for MSMEs in the fashion industry.",
  },
];
