/* eslint-disable no-unused-vars */
import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";
import Page10 from "./Page10";

const Page5 = () => {
  return (
    <Maindiv>    
      <Granddiv>
        <Parentdiv>
          <Headerdiv>
            <Header>Empowering fashion MSMEs of the future.</Header>
          </Headerdiv>
          <Paragraphdiv>
            <Paragraph>
              FFP aims to support existing Micro, Small, and Medium Enterprises
              (MSMEs), particularly female led and youth owned businesses,
              through fashion clusters, to enhance their competitiveness and
              increase productivity.
            </Paragraph>
          </Paragraphdiv>  
        </Parentdiv>
        <Africanwoman>
          <AfricanWormanImage src="/PrettyLady.png" alt="pretty African lady" />
        </Africanwoman>
        <Page10/>
      </Granddiv>
    </Maindiv>
  );
};
export default Page5;

const Maindiv = styled.div`
  background: ${colors.DARK_SHADE_BROWN};
  margin-top: 5rem;
  padding: 3rem 0;

  @media only screen and (max-width: 420px) {
    margin-top: 1rem;
    padding-top: 1rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    margin-top: 1rem;
    padding-top: 1rem;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Granddiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Parentdiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Headerdiv = styled.div`
  width: 45%;
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Paragraphdiv = styled.div`
  width: 45%;
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  color: ${colors.LIGHT_PINK};
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.smallDeskTop};
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: -0.04em;
  text-align: left;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
`;
const Paragraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.LIGHT_PINK};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Africanwoman = styled.div`
  margin-top: 2rem;
  background: ${colors.LIGHT_BROWN};
  border: 3px solid ${colors.LIGHT_BROWN};
  border-radius: 24px;
  padding: 2rem 0;
  text-align: center;
  @media only screen and (max-width: 420px) {
    padding: 2rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const AfricanWormanImage = styled.img`
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 100%;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100%;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
