export const colors = {
  // BASE COLORS
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  FADE_WHITE: "#FFFFFF99",
  LIGHT_WHITE: "#FFFFFFCC",
  LIGHT_BROWN: "#FFFFFF1A",
  GRAY: "#FFFFFFF2",

  // COLOR SCHEME:
  DARK_BROWN: "#2F0E00",
  DEEP_BROWN: "#501800",
  DEEPER_BROWN: "#230B00",
  DARK_SHADE_BROWN: "#190800", 
  ORANGE: "#E45D22",
  DARK_GREY: "#0B0B0B",
  DEEP_GRAY: "#EFEFEF",
  DEEPER_GRAY: "#85888E",
  LIGHT_GRAY:"#E5E5E5",
  RICH_BROWN: "#792500",
  LIGHT_PINK: "#FBEDE0",
  LIGHT_GREY: "#F5F5F5",
  DARK_BROWN_BLACK: "#0C0601",
  MEDIUM_GRAY: "#615D5A",
  DEEP_LINE_GRAY: "#E6E6E6",
  HOVER_BROWN: "#AE4315",
  LIGHTER_BROWN: "#919191",
  ORANGE_LIGHT: "#F37521",
};

export const fonts = {
  FONT_FAMILY_HEADER: "Switzer",
  FONT_FAMILY_BODY: "Inter",
};  

export const fontSizes = {
  extraSmall: "12px",
  small: "14px",
  medium: "16px",
  large: "20px",
  extraLarge: "24px",
  mediumLarge: "40px",
  48: "48px",
  smallDeskTop: "64px",
  deskTop: "72px",
  // Add more font sizes as needed
};



 // "build": "babel src -d dist",,
//  "type": "module",