import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";

const Page4 = () => {
  return (
    <Parentdiv>
      <Childdiv>
        <Header>Find footing in the fashion industry</Header>
        <Paragraph>
          Earn certification and standardized skills that will open doors to job
          opportunities or help you start your own business.
        </Paragraph>
      </Childdiv>

      <Imagediv>
        <Image src="/AfricanMan.png" alt="African" />
      </Imagediv>
    </Parentdiv>
  );  
};
  
export default Page4;

const Parentdiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: 5rem;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 20px;
    margin-top: 1rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 20px;
    margin-top: 1rem;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    align-items: center;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    align-items: center;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    align-items: center;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    align-items: center;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Imagediv = styled.div`
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Image = styled.img`
  height: 60vh;
  @media only screen and (max-width: 420px) {
    height: 30vh !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 30vh !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 30vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 30vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 40vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 60vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Childdiv = styled.div`
  width: 40%;
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 60% !important;
  }

  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 30% !important;
  }
`;
const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 60.4px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
`;
const Paragraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
