import React from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";

const TermMsme = ({ onClose }) => {
  return (
    <>
      <Overlay onClick={onClose} />
      <ModalWrapper>
        <ModalContent>
          <Header>MSME's Consent Form</Header>
          <Ptag>
            Ethnocentrique is committed to improving its programs and ensuring
            they have a positive impact on program businesses. To achieve
            this, we collect and analyze data about program businesses. We
            value your privacy and are committed to protecting your personal and business
            data. This consent form explains how we collect, use, and share your
            personal and business information as part of the Fashion Future Program.
          </Ptag>
          <H6Tag>Please read this form carefully before you decide.</H6Tag>
          <Ptag>Program Name: Fashion Future Program</Ptag>
          <Ptag>Data Collector: Ethnocentrique (info@ethnocentrique.com)</Ptag>
          <ol>
            <LiTag>
              Purpose of Data Collection and Use: We collect your personal and business data
              to make analysis on the progress of the Program and improve the
              Program and learn from the data collected, including monitoring
              and evaluating the effectiveness of the Fashion Future Program and
              to learn how to improve it for future businesses.
            </LiTag>
            <LiTag>
              Who will your data be shared with?
              <Ptag>We may share your data with the following parties:</Ptag>
              <ul>
                <LiTag>Mastercard Foundation</LiTag>
                <LiTag>
                  Third-party data processors hired by Mastercard Foundation to
                  assist with monitoring and evaluation (e.g., data storage and
                  analysis organizations).
                </LiTag>
              </ul>
              For additional information on how Ethnocentrique processes
              personal and business data, please visit{" "}
              <a
                href="https://fashionfutureprogram.org/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://fashionfutureprogram.org/privacy
              </a>{" "}
              to access a copy of its privacy policy. The Foundation’s privacy
              policy can be accessed via{" "}
              <a
                href="https://mastercardfdn.org/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mastercardfdn.org/privacy
              </a>
              .
            </LiTag>
            <LiTag>
              Your Rights:
              <Ptag>
                You are not obligated to give your consent. You are eligible to
                participate in all aspects of the program, regardless of your
                decision to share your data. Consent to data sharing is entirely
                separate from program participation. You have the following
                rights regarding your personal and business data:
              </Ptag>
              <ul>
                <LiTag>
                  Right to access: You have the right to request a copy of the
                  information we hold about you.
                </LiTag>
                <LiTag>
                  Right to rectification: You have the right to ask us to
                  correct any inaccurate information we hold about you.
                </LiTag>
                <LiTag>
                  Right to erasure: You have the right to ask us to delete your
                  information.
                </LiTag>
                <LiTag>
                  Right to restriction of processing: You have the right to ask
                  us to restrict the processing of your information.
                </LiTag>
                <LiTag>
                  Right to object to processing: You have the right to object to
                  the processing of your information.
                </LiTag>
                <LiTag>
                  Right to data portability: You have the right to request that
                  your data be transferred to another organization.
                </LiTag>
              </ul>
            </LiTag>
            <LiTag>
              Withdrawing your consent:
              <Ptag>
                You can withdraw your consent to share your data at any time.
                This will not affect your ability to participate in the program.
                To withdraw your consent, please contact:
                info@ethnocentrique.com
              </Ptag>
            </LiTag>
            <LiTag>
              Understanding your choices:
              <Ptag>
                By consenting to share your data, you are helping for the
                success of Fashion Future Program for you and future
                businesses.
              </Ptag>
              <Ptag>
                I agree to share my data with Ethnocentrique and Mastercard
                Foundation as well as any third-party data processors they may
                use for the purposes described above.
              </Ptag>
            </LiTag>
          </ol>
        </ModalContent>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ModalWrapper>
    </>
  );
};

export default TermMsme;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 50%;
  max-height: 80%;
  overflow-y: auto !important;
  border-radius: 8px;
`;

const ModalContent = styled.div`
  /* white-space: nowrap; */
`;

const CloseButton = styled.button`
  margin-top: 20px;
  background: ${colors.ORANGE};
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.ORANGE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  color: ${colors.WHITE};
  font-size: ${fontSizes.small};
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.extraLarge};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  /* width: 50%; */
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
`;

const H6Tag = styled.h6`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.large};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;
const LiTag = styled.li`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;
