import React from 'react'
import PrivacyPolicy from '../../components/privacy-policy/PrivacyPolicy'

const PrivacyIndex = () => {
  return (
    <div>
        <PrivacyPolicy/>
    </div>
  )
}

export default PrivacyIndex