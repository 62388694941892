import React from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import Index from "../Navbar/Index";

const PrivacyPolicy = () => {
  return (
    <MainDiv>
      <Index />
      <ParentDiv>
        <HeaderMain>Privacy Policy</HeaderMain>
        <div>
          <Header>Ethnocentrique Limited Values your Privacy</Header>
          <Ptag>
            <span>Ethnocentrique Limited</span> values and respects your
            privacy. This<span> “Privacy Statement” </span>summarizes what
            personal information we collect, and how we use and disclose this
            information. By using our website (the “Site”), you signify your
            consent to the terms of our Privacy Statement. If you do not agree
            with any terms of this Privacy Statement, please do not use this
            Site or submit any personal information to us.
          </Ptag>
          <Ptag>
            We reserve the right to modify this Privacy Statement at any time.
            We will reflect any such modifications to this Privacy Statement on
            our Site. Your continued use of the Site after any such changes
            constitutes your acceptance of this Privacy Statement, as revised.
          </Ptag>
        </div>
        <div>
          <Header>What Personal Information We Collect</Header>
          <Ptag>
            <span>Personal information</span> is information about an
            identifiable individual
            <span>(“Personal Information”)</span> . The Organization collects
            Personal Information when you voluntarily provide it through using
            our Site, or when you provide it to us through other means. For
            example, we may collect Personal Information when you:
            <ul>
              <li>
                contact us through email, telephone, mail; or other
                correspondence;
              </li>
              <li>apply to participate in one of our programs</li>
            </ul>
          </Ptag>
          <Ptag>
            The Personal Information we may collect will depend on the nature of
            your request or application, and may include your full name, contact
            information, such as your address, telephone number, or email
            address, date of birth, identification numbers (such as passport or
            driver’s license numbers), details of your background, photographs
            or videos or any other Personal Information that you choose to
            submit to us.
          </Ptag>
        </div>
        <div>
          <Header>Consent</Header>
          <Ptag>
            We only collect, use and disclose Personal Information with the
            consent of the individual. Your knowledge and consent are required
            for us to collect, use or disclose your Personal Information, except
            in certain limited circumstances. By using this Site, you are giving
            your consent for us to collect, use or disclose your Personal
            Information. If you do not want us to collect and use your Personal
            Information, then you should discontinue using our Site. You should
            also understand that, in many cases, our collection, use, or
            disclosure of Personal Information is a valid condition of service,
            which means that we need your Personal Information in order to
            provide a particular service, such as participation in our programs.
            If you do not wish to consent to the collection, use or disclosure
            of your Personal Information, we may not be able to do certain
            things, such as process your application for a program.
          </Ptag>
        </div>
        <div>
          <Header>
            How Ethnocentrique Collects Information through Technological Means
          </Header>
          <Ptag>
            When you visit our Site, we may collect information that is
            automatically sent to us by your web browser. This information may
            include your domain name, and your numerical IP address. We may also
            collect other information, such as the type of browser you use,
            which pages you view and the files you request.
          </Ptag>
          <Ptag>
            We collect this information to better understand how visitors use
            our Site, to improve the content of the Site and to improve your
            interaction with the Site by making it easier for you to get back to
            the pages you have looked at the next time you visit. The amount of
            information that is sent by your web browser depends on the browser
            and settings you use. Please refer to the instructions provided by
            your browser if you want to learn more about what information it
            sends to websites you visit, or how you may change or restrict this.
          </Ptag>
          <Ptag>
            Ethnocentrique may use “cookies” and other similar devices on this
            Site to enhance functionality. These devices may track information
            which includes, but is not limited to: (i) IP address; (ii) the type
            of web browser and operating system used; and (iii) the pages of the
            Site visited. If you wish to disable cookies, refer to your browser
            help menu to learn how. If you disable cookies, you may be unable to
            access some features on this Site.
          </Ptag>
        </div>
        <div>
          <Header>
            How Ethnocentrique Uses and Discloses Personal Information
          </Header>
          <Ptag>
            The Personal Information we collect may be used by the Organization
            for the purposes for which it was collected, as provided in this
            Privacy Statement, or for other purposes that are disclosed to you.
            By using this Site, you are giving your consent for us to collect,
            use or disclose your Personal Information for these purposes.
          </Ptag>
          <Ptag>
            For example, we may use your Personal Information to: process your
            application to our programs; determine whether you are eligible for
            our programs; manage, operate, oversee and administer our programs;
            Mastercard Foundation and Third-party data processors hired by
            Mastercard Foundation to assist with monitoring and evaluation
            (e.g., data storage and analysis organizations); or to carry out any
            other purpose permitted or required by law. We also may disclose
            your Personal Information for any other purpose that we tell you
            about and to which you consent.
          </Ptag>
        </div>
        <div>
          <Header>Children and Youth</Header>
          <Ptag>
            Although the collection of some Personal Information of children and
            youth is necessary to pro-vide our services, we do not collect
            Personal Information from children under the age of 13 unless we
            have the consent of their parents or guardians. If you are a child
            or youth, you should review this Privacy Statement with your parents
            or guardians to make sure that you understand and consent to
            everything in it. If you are under the age of 13, you should not
            access this Site or provide us with any Personal Information unless
            your parent or guardian has consented. If we discover that we have
            collected Personal Information from a child under the age of 13
            without parental consent, we will delete that Personal Information.
          </Ptag>
        </div>
        <div>
          <Header>How We Protect Personal Information</Header>
          <Ptag>
            The security of your Personal Information is important to us. We
            protect your Personal Information by maintaining physical,
            organizational and technological safeguards appropriate to the
            sensitivity of such Personal Information. Personal Information may
            only be accessed by persons within the Organization who require such
            access to carry out our purposes as described above.
          </Ptag>
          <Ptag>
            We retain Personal Information that we collect only as long as
            necessary for the purposes for which it was collected or to meet
            legal requirements. We securely destroy Personal Information when it
            is no longer needed.
          </Ptag>
        </div>
        <div>
          <Header>Access and Correction</Header>
          <Ptag>
            You have a right to access to your Personal Information and to
            request a correction to it if you believe it is inaccurate. If you
            have submitted Personal Information and would like to have access to
            it, or if you would like to have it corrected, please contact us
            using the contact information provided below.
          </Ptag>
        </div>
        <div>
          <Header>How to Contact Us</Header>
          <Ptag>
            If you have any questions regarding this Privacy Statement or to
            access your information, please contact our Privacy Officer at:
          </Ptag>
          <Ptag>Privacy Officer</Ptag>
          <Ptag>Ethnocentrique Limited</Ptag>
          <Ptag>4b Prince Kayode Akingbade Close,</Ptag>
          <Ptag>Off Muri Okunola Street, VI, Lagos</Ptag>
          <Ptag>email: privacy@ethnocentrique.com</Ptag>
        </div>
      </ParentDiv>
    </MainDiv>
  );
};

export default PrivacyPolicy;

const MainDiv = styled.div`
  background: linear-gradient(252.87deg, #f7ece0 28.09%, #fbede0 82.77%),
  url("/Hero.svg");
`

const ParentDiv = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 3rem 0;


  @media only screen and (max-width: 420px) {
    width: 80%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 70%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 50%;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50%;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50%;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};

  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};

    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.extraLarge};
  font-weight: 700;
  line-height: 30.4px !important;
  letter-spacing: -0.04em;
  text-align: left;
  width: 80%;
  color: ${colors.DARK_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes.extraLarge} !important;
  }
`;
const HeaderMain = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.smallDeskTop};
  font-weight: 700;
  line-height: 30.4px !important;
  letter-spacing: -0.04em;
  text-align: left;
  width: 80%;
  margin-bottom: 5rem;
  color: ${colors.DARK_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
