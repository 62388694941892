import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";
import { Testimonial } from "./Page8-Data";

const Page8 = () => {
  return (
    <Maindiv>
      <Granddiv>
        <Parentdiv>
          <Headerdiv>
            <Header>Be part of the future of Nigerian fashion</Header>
          </Headerdiv>
          <Paragraphdiv>
            <Paragraph>
              As the largest fashion cluster in West Africa, Aba is a bustling
              hub for garment, footwear, and leatherwork production. Its rich
              tradition of craftsmanship and entrepreneurial spirit makes it the
              perfect focal point for developing and nurturing new talent in the
              fashion industry.
            </Paragraph>
          </Paragraphdiv>   
        </Parentdiv>
        <TestimonialParent>
          {Testimonial.map((d) => (
            <TestimonialChild key={d.id}>
              {/* <img src={d.image} alt={d.title} /> */}
              <TesContainer>
              <img src={d.image} alt={d.title} />
                <TestimonailHeader> {d.title} </TestimonailHeader>
                {/* <TesimonialParagraph> {d.body} </TesimonialParagraph> */}
              </TesContainer>
            </TestimonialChild>
          ))}
        </TestimonialParent>
      </Granddiv>  
    </Maindiv>    
  );
};

export default Page8;

const Maindiv = styled.div`
  background: ${colors.LIGHT_PINK};
  padding: 5rem 0 !important;
  padding-bottom: 6rem !important;

  @media only screen and (max-width: 420px) {
    /* margin-top: 1rem; */
    padding-top: 1rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    /* margin-top: 1rem; */
    padding-top: 1rem;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Granddiv = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Parentdiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
 
  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Headerdiv = styled.div`
  width: 40%;
  
  
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Paragraphdiv = styled.div`
  width: 60%;
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  color: ${colors.DARK_BROWN};
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 57.6px;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.mediumLarge} !important;
    line-height: 50px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
`;
const Paragraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  /* text-align: left; */
  text-align: center;
  color: ${colors.DARK_BROWN};
  padding: 0;
  padding-top: 1rem;
  margin: 0;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TestimonialChild = styled.div`
  padding: 48px 32px 48px 32px;
  padding: 20px !important;
  background: ${colors.WHITE};
  border-radius: 12px;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TestimonialParent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  max-width: 100%;
  margin-top: 3rem;

  @media only screen and (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const TesContainer = styled.div`
  /* padding-top: 1rem; */
  display: flex;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TestimonailHeader = styled.h6`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${colors.DARK_BROWN_BLACK};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.small};
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.medium};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.medium};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const TesimonialParagraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  color: ${colors.MEDIUM_GRAY};
  padding: 0;
  padding-top: 0.5rem !important;
  margin: 0;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
