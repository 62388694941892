/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState } from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../../util/Style-Guide";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Indicator from "../StepIndicator/Indicator";
import IndicatorMsme from "../StepIndicator/IndicatorMsme";

const Page1 = ({ formData, setFormData, nextStep, prevStep }) => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.ageRange) newErrors.ageRange = "Age range is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.meansOfId) newErrors.meansOfId = "Mean of ID is required";
    if (!formData.educationCompleted)
      newErrors.educationCompleted = "Education Completed is required";
    if (!formData.otherEduation && formData.educationCompleted === "others")
      newErrors.otherEduation = "Other Education is required";
    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be numeric";
    }
    if (!formData.phonewhatsapp) {
      newErrors.phonewhatsapp = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phonewhatsapp)) {
      newErrors.phonewhatsapp = "Phone number must be numeric";
    }
    const validateEmail = (email) => {
      if (!email) {
        return "Email field is required";
      }
      if (!email.includes("@")) {
        return "Email must contain an '@' symbol.";
      }
      if (!email.includes(".")) {
        return "Email must contain a '.' character.";
      }
      if (!/\S+@\S+\.\S+/.test(email)) {
        return "Email must be a valid email address, containing an '@' symbol and a '.' character.";
      }
      return "";
    };

    const emailError = validateEmail(formData.email);
    if (emailError) {
      newErrors.email = emailError;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Business Information</Header>
            <IndicatorMsme currentStep={1} totalSteps={5}/>
            <Ptag>
              Let’s get to know you enter your accurate business information for
              the program.
            </Ptag>

            <ButtonWrapper>
              <ApplyButton onClick={navigateToHome}>Back to home</ApplyButton>
            </ButtonWrapper>
          </Content>

          <StyledForm>
            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="first-name">First Name</StyledLabel>
                <StyledInput
                  type="text"
                  id="first-name"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  required
                />
                {errors.firstName && (
                  <ErrorMessage>{errors.firstName}</ErrorMessage>
                )}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="last-name">Last Name</StyledLabel>
                <StyledInput
                  type="text"
                  id="last-name"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                />
              </StyledChild>
            </StyledChildDiv>

            <StyledChildDiv>
            <StyledChild>
              <StyledLabel htmlFor="email">Email</StyledLabel>
              <StyledInput
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </StyledChild>
             
             <StyledChild>
               <StyledLabel htmlFor="means-of-id">Means of ID</StyledLabel>
               <StyledSelect
                 id="means-of-id"
                 name="meansOfId"
                 value={formData.meansOfId}
                 onChange={(e) =>
                   setFormData({ ...formData, meansOfId: e.target.value })
                 }
               >
                 <option value="">Select an option</option>
                 <option value="NIN">NIN</option>
                 <option value="Voters card">Voters card</option>
                 <option value="Drivers License">Drivers License</option>
                 <option value="Passport">Passport</option>
               </StyledSelect>
               {errors.meansOfId && (
                 <ErrorMessage>{errors.meansOfId}</ErrorMessage>
               )}
             </StyledChild>
           </StyledChildDiv>
         

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="phone">Phone Number (SMS)</StyledLabel>
                <StyledInput
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Enter your phone number"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  required
                />
                {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="phone">
                  Phone Number (WhatsAPP)
                </StyledLabel>
                <StyledInput
                  type="tel"
                  id="phonewhatsapp"
                  name="phonewhatsapp"
                  placeholder="Enter your phone number"
                  value={formData.phonewhatsapp}
                  onChange={(e) =>
                    setFormData({ ...formData, phonewhatsapp: e.target.value })
                  }
                  required
                />
                {errors.phonewhatsapp && (
                  <ErrorMessage>{errors.phonewhatsapp}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

            <StyledChildDiv>
              <StyledChild>
                <StyledLabel htmlFor="gender">Gender</StyledLabel>
                <StyledSelect
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                >
                  <option value="">Select an option</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </StyledSelect>
                {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
              </StyledChild>
              <StyledChild>
                <StyledLabel htmlFor="age-range">Age Range</StyledLabel>
                <StyledSelect
                  id="age-range"
                  name="ageRange"
                  value={formData.ageRange}
                  onChange={(e) =>
                    setFormData({ ...formData, ageRange: e.target.value })
                  }
                >
                  <option value="">Select an option</option>
                  <option value="18-25">18-25</option>
                  <option value="26-30">26-30</option>
                  <option value="31-45">31-45</option>
                  <option value="46-60">46-60</option>
                  <option value="60-above">60-above</option>
                </StyledSelect>
                {errors.ageRange && (
                  <ErrorMessage>{errors.ageRange}</ErrorMessage>
                )}
              </StyledChild>
            </StyledChildDiv>

           
            <StyledChild>
                <StyledLabel htmlFor="educationCompleted">
                  Highest Level of Education Completed
                </StyledLabel>
                <StyledSelect
                  id="educationCompleted"
                  name="educationCompleted"
                  defaultValue={formData.educationCompleted}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      educationCompleted: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select an option</option>
                  <option value="noFormalEducation">No formal education</option>
                  <option value="primary">Primary education</option>
                  <option value="secondary">
                    Secondary education (e.g., O'Levels, SSCE, NECO)
                  </option>
                  <option value="undergraduate">
                    Undergraduate (e.g Degree in view)
                  </option>
                  <option value="vocational">
                    Vocational/Technical education (e.g., NTC, NABTEB)
                  </option>
                  <option value="tertiary">
                    Tertiary education (e.g., NCE, ND, HND, BSc, BA, BTech)
                  </option>
                  <option value="postgraduate">
                    Postgraduate education (e.g., PGD, MSc, MA, MBA, PhD)
                  </option>
                  <option value="others">Other Education</option>
                </StyledSelect>
                {errors.educationCompleted && (
                  <ErrorMessage>{errors.educationCompleted}</ErrorMessage>
                )}
              </StyledChild>
              {formData.educationCompleted === "others" && (
                <StyledChild>
                  <StyledLabel htmlFor="otherEduation">
                    Other Educational Qualifications (if applicable)
                  </StyledLabel>
                  <StyledInput
                    type="text"
                    id="otherEduation"
                    name="otherEduation"
                    required
                    defaultValue={formData.otherEduation}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        otherEduation: e.target.value,
                      })
                    }
                  />
                  {errors.otherEduation && (
                    <ErrorMessage>{errors.otherEduation}</ErrorMessage>
                  )}
                </StyledChild>
              )}

            <ButtonWrapper>
         
              <StyledButton2 onClick={handleNextStep}> Next</StyledButton2>
            </ButtonWrapper>
          </StyledForm>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Page1;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    padding: 0rem !important;
  }
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    padding: 10px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 10px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* min-height: 100% !important; */
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 80% !important;
    min-width: 80% !important;
    margin: 0 !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    padding: 1rem !important;
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* padding: 3rem 0 !important; */
    max-width: 100% !important;
    min-width: 80% !important;
    border-radius: 24px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    /* padding: 3rem 0 !important; */
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 40% !important;

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0;
  padding: 0;
  width: 50%;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
    padding-bottom: 1rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  width: 70% !important;
  /* margin: 0;
padding: 0; */
`;

const ApplyButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const StyledForm = styled.form`
  width: 40% !important;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
    margin-top: 1rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 50% !important;
  }
`;

const StyledChild = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 60%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    /* width: 100%; */
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 30%;
  }
`;
const StyledChildDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    gap: 0;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-direction: column !important;
    gap: 0;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const StyledLabel = styled.label`
  color: ${colors.BLACK};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 150% */
`;
const StyledInput = styled.input`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 3vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;
const StyledSelect = styled.select`
  border-radius: var(--Radius-radius-sm, 8px);
  border: 1px solid ${colors.LIGHT_GRAY};
  background: ${colors.DEEP_GRAY};
  color: ${colors.DEEPER_GRAY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 4px 12px 4px 12px !important;
  margin-top: 0.5rem;
  height: 6vh !important;

  &:focus {
    outline: ${`1px solid ${colors.PINK}`};
    font-family: ${fonts.FONT_FAMILY};
  }

  @media only screen and (max-width: 420px) {
    height: 5vh !important;
    font-size: 12px !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    height: 5vh !important;
    font-size: 12px !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    height: 5vh !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    height: 5vh;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const StyledButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.WHITE};
  border-radius: 12px;
  color: ${colors.DARK_GREY};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const StyledButton2 = styled.button`
  padding: 1.2rem 3rem;
  background: ${colors.ORANGE};
  border-radius: 12px;
  color: ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  line-height: 16px;
  width: 100% !important;
  border: 0px solid ${colors.ORANGE};
  margin-top: 0rem;
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }

  @media only screen and (max-width: 420px) {
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 50% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
