/* eslint-disable no-undef */
import React from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";

import { useNavigate } from "react-router-dom";

const Apply = ({ nextStep }) => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");   
  };

  return (
    <FullScreenBackground>
      <CenteredContentWrapper>
        <ContentWrapper>
          <Content>
            <Header>Apply for this program</Header>
            <Ptag>
              The Fashion Future Program (FFP) is an initiative tailored to
              equip young fashion enthusiasts and entrepreneurs, particularly
              women, with the skills, resources, and opportunities needed to
              excel in today's fashion industry.
              <Ptag>
                Through apprenticeship training, hands-on mentorship, and robust
                capacity building, our goal is to nurture a dynamic community of
                young fashion trendsetters. By fostering collaboration within
                this community through clusters, participants will thrive as
                individuals, and contribute to the broader economic development
                and global recognition of Nigeria's fashion industry.
              </Ptag>
              <Ptag>
                Implemented by Ethnocentrique in collaboration with the
                Mastercard Foundation, FFP aims to equip 5,000 youths with
                technical and business skills in tailoring, shoemaking and
                leather work. Additionally, the program will support 4,000
                MSMEs, especially women-led businesses, creating an ecosystem
                where creativity and entrepreneurship prosper.
              </Ptag>
            </Ptag>
            <ButtonWrapper>
              <BackButton onClick={navigateToHome}>Back to home</BackButton>
              <ApplyButton onClick={nextStep}>Apply now</ApplyButton>
            </ButtonWrapper>
          </Content>
  
          <ContentChild>
            <H6Tag>Benefits</H6Tag>

            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                Vocational Skills Training: Gain expert training in garment
                making, footwear design, and leatherworks to master essential
                fashion skills.
              </PBody>
            </EclipseDiv>
            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                Paid Apprenticeships: Earn while you learn through hands-on
                apprenticeship opportunities that provide real-world experience.
              </PBody>
            </EclipseDiv>
            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                Post-Training Starter Packs: Receive essential tools and
                resources to kickstart your fashion career or business after
                completing the program.
              </PBody>
            </EclipseDiv>
            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                Market Access: Connect with new markets and expand your business
                reach
              </PBody>
            </EclipseDiv>
            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                Access to Competitive Infrastructure: Utilize facilities and
                resources designed to boost your productivity and creativity.
              </PBody>
            </EclipseDiv>
            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                Job Opportunities: Open doors to various job prospects within
                the fashion industry
              </PBody>
            </EclipseDiv>
            <EclipseDiv>
              <img src="/Ellipse.svg" alt="Ellipse" />
              <PBody>
                NSQF Level 2 Certification: Earn a nationally recognized
                qualification that validates your skills and increases your
                employability
              </PBody>
            </EclipseDiv>
            <HowtoApply>
              <H6Tag>Who Can Apply? </H6Tag>

              <EclipseDiv>
                <img src="/Ellipse.svg" alt="Ellipse" />
                <PBody>Youths between the ages of 18 - 35</PBody>
              </EclipseDiv>
              <EclipseDiv>
                <img src="/Ellipse.svg" alt="Ellipse" />
                <PBody>Residents of Abia State, Nigeria </PBody>
              </EclipseDiv>
              <EclipseDiv>
                <img src="/Ellipse.svg" alt="Ellipse" />
                <PBody>
                  Individuals with a passion for fashion and a desire to develop
                  their skills and business acumen{" "}
                </PBody>
              </EclipseDiv>
            </HowtoApply>
          </ContentChild>
        </ContentWrapper>
      </CenteredContentWrapper>
    </FullScreenBackground>
  );
};

export default Apply;


const HowtoApply = styled.div`
  margin-top: 1rem;
`

const FullScreenBackground = styled.div`
  background-image: url("/Formbackground.png");
  background-size: cover;
  background-position: center;


  display: flex;
  justify-content: center;
  align-items: center;


  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    padding: 2rem !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    padding: 0rem !important;
  }
  
`;

const CenteredContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 10px;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-height: 100% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
    /* min-height: 90vh !important; */
  }

`;
const ContentWrapper = styled.div`
   display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.GRAY};
  max-width: 1200px !important;
  min-width: 1200px !important;
  margin: 0 auto;
  border-radius: 40px;
  padding: 3rem !important;
  min-height: 80vh !important;  

  


  @media only screen and (max-width: 420px) {
    flex-direction: column;
    padding: 1rem !important;
    border-radius: 24px;
    max-width: 100% !important;
    min-width: 80% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
    padding: 1rem;
    border-radius: 24px;
    max-width: 100% !important;
    min-width: 80% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    align-items:start;
    min-width: 80% !important;
  
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    min-width: 80% !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Content = styled.div`
  /* padding: 20px; */
  width: 45%;
  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
  }
`;

const ContentChild = styled.div`
  width: 45%;
  @media only screen and (max-width: 420px) {
    width: 100%;
    margin-top: 2rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

const Header = styled.h1`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DARK_BROWN};
  margin: 0 !important;
  padding: 0 !important;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.small};
    line-height: 24px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.large};
    line-height: 0px !important;
    padding-bottom: 1rem !important;

  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 0px !important;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 0px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.extraLarge};
    line-height: 24px;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Ptag = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    width: 100% !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};

    width: 100% !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 2rem !important;

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    flex-wrap: wrap !important;
  }
`;

const ApplyButton = styled.button`
  background: ${colors.ORANGE};
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.ORANGE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  color: ${colors.WHITE};
  font-size: ${fontSizes.small};
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN} !important;
  }
  &:active {
    background: ${colors.ORANGE_LIGHT};
  }
`;

const BackButton = styled.button`
  background: ${colors.WHITE};
  gap: 10px;
  padding: 1.2rem 3rem;
  border-radius: 10px;
  border: 0px solid ${colors.WHITE};
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  cursor: pointer;
`;

const H6Tag = styled.h6`
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes.large};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;

const PBody = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.02em;
  text-align: left;
  color: ${colors.DEEP_BROWN};
  margin: 0;
  padding: 0;
`;

const PBodyParent = styled(PBody)`
  padding-top: 0.5rem;
`;

const EclipseDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 0.5rem;
`;
