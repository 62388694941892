import React from "react";
import Index from "../../components/home/Index";

const HomePageIndex = () => {


  return (
    <div>
      <Index />
    </div>
  );
};

export default HomePageIndex;
