import React from "react";
import Onboarding from "../../components/Forms/onboarding-flow/Onboarding";

const OnboardingIndex = () => {
  return (
    <div>
      <Onboarding />
    </div>
  );
};
 
export default OnboardingIndex;
