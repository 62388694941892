import React from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate("/registration-flow");
    // navigate("/registration-form");
  };

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <Container>
      <ApplyNow>
        <Image src="/Logo.svg" alt="FSCDP Logo" onClick={navigateToHome} />
        <Button onClick={navigateToSignup}>Apply now</Button>
      </ApplyNow>
    </Container>
  );
};
export default Index;
const Container = styled.div`
  display: flex !important;
  justify-content: center !important;
`;
const ApplyNow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 200px;
  padding: 0.2rem 1rem;
  padding-right: 0.2rem;
  background: ${colors.FADE_WHITE};
  border: 1px solid ${colors.LIGHT_WHITE};
  position: fixed !important;
  margin: 0 auto !important;
  /* top: 10%; */
  /* left: 10%; */
  z-index: 1000;
  width: 100%;
  max-width: 1200px;

  @media only screen and (max-width: 420px) {
    max-width: 100% !important;
    width: 85%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 85%;
    max-width: 100%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 85%;
    max-width: 100%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 85%;
    max-width: 100%;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    width: 85% !important;
    max-width: 80% !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 85%;
    max-width: 100%;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Image = styled.img`
  height: 30px;
  cursor: pointer;
`;

const Button = styled.button`
  padding: 16px 24px;
  border-radius: 56px;
  font-family: ${fonts.FONT_FAMILY_BODY};
  background: ${colors.ORANGE};
  color: ${colors.WHITE};
  border: 0px solid ${colors.ORANGE};
  cursor: pointer;
  &:hover {
    background: ${colors.DARK_BROWN};
  }

  &:active {
    background: ${colors.ORANGE_LIGHT};
  }
`;
