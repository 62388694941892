/* eslint-disable no-unused-vars */
import React from "react";
import { colors, fonts, fontSizes } from "../../util/Style-Guide";
import styled from "styled-components";
// import Ring from "../../assets/images/Ring.png";
// import Cube from "../../assets/images/Cube.png";

const Page7 = () => {
  return (
    <Maindiv>
      <Headermain>
        <Header>This programe is completely free —Zero fees</Header>
      </Headermain>

      <Ringparent>
        <ParagraphParent>
          <Paragraph>
            Join us in crafting a prosperous future driven by creativity. Become
            a part of a dynamic community of trendsetters who are redefining the
            fashion industry.
          </Paragraph>

          <H6>Program starts</H6>
          <Role>September 2024</Role>
        </ParagraphParent>

        <RingImageParent src="/Ring.png" alt=" ring " />
      </Ringparent>
      <LadyInHatImage src="/LadyInHat.png" alt="African Woman" />

      <Ringparent2>
        <RingImageParent src="/Cube.png" alt="Cube" />
        <ParagraphParent2>
          <Paragraph2>
            This training program is organized by Ethnocentrique in partnership
            with The Mastercard foundation.
          </Paragraph2>

          <ImageDiv>
            <div>
              <H62>In partnership with</H62>
              <img src="/MasterCard.svg" alt="MasterCard Foundation" />
            </div>
            <div>
              <H62>Implemented by</H62>
              <Image src="/Logoflip.png" alt="FSCDP Logo" />
            </div>
          </ImageDiv>
        </ParagraphParent2>
      </Ringparent2>
    </Maindiv>
  );
};
export default Page7;

const Maindiv = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  /* margin-top: 3rem; */
  padding: 3rem 0;
  @media only screen and (max-width: 420px) {
    padding: 0 !important;
    padding-bottom: 2rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    padding: 0 !important;
    padding-bottom: 2rem !important;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;
const Headermain = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 0 20px;
  @media only screen and (max-width: 420px) {
    width: 80%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
  }
`;

const Header = styled.h1`
  color: ${colors.DARK_BROWN};
  font-family: ${fonts.FONT_FAMILY_HEADER};
  font-size: ${fontSizes[48]};
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: -0.04em;
  text-align: center;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
    text-align: center;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 30px;
  }
  @media only screen and (min-width: 922px) and (max-width: 1200px) {
    font-size: ${fontSizes.extraLarge} !important;
    line-height: 40px !important;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
    font-size: ${fontSizes.mediumLarge} !important;
    line-height: 50px !important;
  }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    font-size: ${fontSizes[48]} !important;
    line-height: 60px !important;
  }
`;

const Ringparent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 300px;
  padding-top: 3rem !important;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    gap: 0;
    padding-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    gap: 0;
    padding-top: 1rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    gap: 160px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    gap: 160px;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    gap: 160px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const LadyInHatImage = styled.img`
  height: 80vh;
  margin-top: -12rem;
  @media only screen and (max-width: 420px) {
    margin-top: 0rem !important;
    height: 40vh;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    margin-top: 0rem !important;
    height: 40vh;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    margin-top: -8rem !important;
    height: 40vh;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    margin-top: -8rem !important;
    height: 60vh;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    margin-top: -8rem !important;
    height: 60vh;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const Ringparent2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 300px;
  margin-top: -12rem;
  @media only screen and (max-width: 420px) {
    flex-direction: column !important;
    gap: 0;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    flex-direction: column !important;
    gap: 0;
    margin-top: 1rem !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const Paragraph = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.medium};
  font-weight: 600;
  color: ${colors.WHITE};
  padding: 0;
  margin: 0;
  line-height: 25px;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.small};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const H6 = styled.h6`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: ${colors.WHITE};
  padding: 0;
  margin: 0;
  padding-top: 1rem;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
    padding-top: 0rem;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
    text-align: center;
    padding-top: 0rem;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const Role = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  color: ${colors.WHITE};
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 420px) {
    text-align: center;
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    text-align: center;
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const ParagraphParent = styled.div`
  background: ${colors.ORANGE_LIGHT};
  padding: 40px 20px;
  border-radius: 24px;
  width: 30% !important;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  @media only screen and (max-width: 420px) {
    width: 80% !important;
    text-align: center;
    padding: 20px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80% !important;
    text-align: center;
    padding: 20px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* width: 80% !important; */
    padding: 20px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    /* width: 80% !important; */
    padding: 20px;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const RingImageParent = styled.img`
  height: 20vh;
  @media only screen and (max-width: 420px) {
    display: none !important;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    display: none !important;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* display: none !important; */
    height: 15vh;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    height: 15vh;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;
const ParagraphParent2 = styled.div`
  background: ${colors.LIGHT_GREY};
  padding: 40px 20px;
  border-radius: 24px;
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.large};
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 30%;
  @media only screen and (max-width: 420px) {
    width: 80% !important;
    text-align: center;
    padding: 20px;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80% !important;
    text-align: center;
    padding: 20px;
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* width: 100% !important; */
    padding: 20px;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    /* width: 100% !important; */
    padding: 20px;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const Paragraph2 = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  font-weight: 600;
  color: ${colors.BLACK};
  padding: 0;
  margin: 0;
  line-height: 25px;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const H62 = styled.h6`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.extraSmall};
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: ${colors.BLACK};
  padding: 0;
  margin: 0;
  padding-top: 1rem;
  @media only screen and (max-width: 420px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    font-size: ${fontSizes.extraSmall};
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const Role2 = styled.p`
  font-family: ${fonts.FONT_FAMILY_BODY};
  font-size: ${fontSizes.small};
  color: ${colors.BLACK};
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  padding: 0;
  margin: 0;
`;

const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center !important;
  gap: 20px;
  @media only screen and (max-width: 420px) {
    /* flex-direction: column; */
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;

const Image = styled.img`
  height: 50px;

  @media only screen and (max-width: 420px) {
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
  }
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    /* height: 10px; */
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    /* height: 10px; */
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1201px) and (max-width: 1400px) {
  }
  @media only screen and (min-width: 1401px) and (max-width: 1500px) {
  }
`;
