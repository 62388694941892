

export const data = [
  {
    id: 1,
    image: "/testimonial1.png",
  },
  {
    id: 2,
    image: "/testimonial2.png",
  },
  {
    id: 3,
    image: "/testimonial3.png",
  },
  {
    id: 4,
    image:"/testimonial4.png",  
  },
  {
    id: 5,
    image: "/testimonial5.png",
  },
  // {
  //   id: 6,
  //   image: "/testimonial6.svg",
  // },
  // {
  //   id: 7,
  //   image: "/testimonial7.svg",
  // },
  // {
  //   id: 8,
  //   image: "/testimonial8.svg",
  // },
  // {
  //   id: 9,
  //   image: "/testimonial9.svg",
  // },
];
