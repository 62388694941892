import React from "react";

const Error = () => {
  return (
    <div>
      <h1>An error occured!!</h1>
      <p>Could not find this page</p>
    </div>
  );
};

export default Error;