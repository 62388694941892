export const statesInNigeria = [
    { name: "Abia", value: "Abia" },
    { name: "Adamawa", value: "Adamawa" },
    { name: "Akwa Ibom", value: "Akwa Ibom" },
    { name: "Anambra", value: "Anambra" },
    { name: "Bauchi", value: "Bauchi" },
    { name: "Bayelsa", value: "Bayelsa" },
    { name: "Benue", value: "Benue" },
    { name: "Borno", value: "Borno" },
    { name: "Cross River", value: "Cross River" },
    { name: "Delta", value: "Delta" },
    { name: "Ebonyi", value: "Ebonyi" },
    { name: "Edo", value: "Edo" },
    { name: "Ekiti", value: "Ekiti" },
    { name: "Enugu", value: "Enugu" },
    { name: "FCT", value: "FCT" },
    { name: "Gombe", value: "Gombe" },
    { name: "Imo", value: "Imo" },
    { name: "Jigawa", value: "Jigawa" },
    { name: "Kaduna", value: "Kaduna" },
    { name: "Kano", value: "Kano" },
    { name: "Katsina", value: "Katsina" },
    { name: "Kebbi", value: "Kebbi" },
    { name: "Kogi", value: "Kogi" },
    { name: "Kwara", value: "Kwara" },
    { name: "Lagos", value: "Lagos" },
    { name: "Nasarawa", value: "Nasarawa" },
    { name: "Niger", value: "Niger" },
    { name: "Ogun", value: "Ogun" },
    { name: "Ondo", value: "Ondo" },
    { name: "Osun", value: "Osun" },
    { name: "Oyo", value: "Oyo" },
    { name: "Plateau", value: "Plateau" },
    { name: "Rivers", value: "Rivers" },
    { name: "Sokoto", value: "Sokoto" },
    { name: "Taraba", value: "Taraba" },
    { name: "Yobe", value: "Yobe" },
    { name: "Zamfara", value: "Zamfara" },
  ];
  