import React, { useState } from "react";
import Personal from "../Personal";
import Apply from "../Apply";
import Residential from "../Residential"; 
import KoboForm from "../Kobo COllect";
import PersonalTwo from "../PersonalTwo";
import Page1 from "../BackgroundExperience/Page1";
import Page2 from "../BackgroundExperience/Page2";
import { v4 as uuidv4 } from "uuid";
import emailjs from '@emailjs/browser';

const Onboarding = () => {
  //Refactor to use useQuery
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // Initialize with empty values for each form field
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",  
    maritalStatus: "",
    residentialAddress: "",
    state: "",
    livingSituation: "",
    mainpersonearningmoney: "",
    careTaker: [],
    childrenbelowfive: "",
    disabilitySpecialNeeds: "",
    specifyDisabilities: "",
    support: "",
    supportNeeded: "",
    educationCompleted: "",
    otherEduation: "",
    employmentStatus: "",
    currentWork: "",
    earningsPerMonth: "",
    smartPhone: "",
    oftenUsedApp: [],
    pleaseSpecify: "",
    availability: "",
    areaOfInterest: "",
    pastTraining: "",
    whatArea: "",
    experienceLevel: "",
    heardAboutUs: [],
    terms_and_conditions: "",
    privacy_policy: "",
    select_intake_agent: "",
    instanceID: `uuid:${uuidv4()}`,
    uniqueId: `${uuidv4()}`,

    // Add other form layers similarly
  });

  // Function to handle navigation to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Function to handle navigation to previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Render different form components based on the current step
  const renderStep = () => {
    switch (step) {
      case 1:
        return <Apply nextStep={nextStep} />;
      case 2:
        return (
          <Personal
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <PersonalTwo
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <Residential
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <Page1
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 6:
        return (
          <Page2
            nextStep={nextStep}  
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 7:
        return (
          <KoboForm
            submitData={submitData}
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
          />
        );
      // Add cases for other form steps  formData={formData}
      default:
        return <div>No form step found</div>;
    }
  };


  // /* /index.html 200

  // const apiUrl = process.env.REACT_APP_GOOGLE_SHEETS_API_URL;
  // console.log(apiUrl, "API URL")

  const submitData = async () => {
    // console.log(formData, "formdata");

    const apiUrl = process.env.REACT_APP_GOOGLE_SHEETS_API_URL;
    // console.log(apiUrl, "API URL")
  
    // Convert the careTaker array into a comma-separated string
    const formattedFormData = {
      ...formData,
      careTaker: formData.careTaker.join(", "),
      oftenUsedApp: formData.oftenUsedApp.join(", "),
      heardAboutUs: formData.heardAboutUs.join(", "),  
    };

    try {
      const response = await fetch(apiUrl, {  
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([[...Object.values(formattedFormData)]]),
      });
      const dataReponse = await response.json();
      console.log(dataReponse);


      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          user_email: formData.email,
          user_name: formData.firstName,
          // Add other template parameters if needed
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );



      // Reset form data after successful submission
      setFormData({
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        phone: "",
        email: "",
        maritalStatus: "",
        residentialAddress: "",
        state: "",
        livingSituation: "",
        mainpersonearningmoney: "",
        careTaker: [],
        childrenbelowfive: "",
        disabilitySpecialNeeds: "",
        specifyDisabilities: "",
        support: "",
        supportNeeded: "",
        educationCompleted: "",
        otherEduation: "",
        employmentStatus: "",
        currentWork: "",
        earningsPerMonth: "",
        smartPhone: "",
        oftenUsedApp: [],
        pleaseSpecify: "",
        availability: "",
        areaOfInterest: "",
        pastTraining: "",
        whatArea: "",
        experienceLevel: "",
        heardAboutUs: [],
        terms_and_conditions: "",
        privacy_policy: "",
        select_intake_agent: "",
        instanceID: `uuid:${uuidv4()}`,
        uniqueId: `${uuidv4()}`,
        // Add other form layers similarly
      });
      // Navigate to the next step or perform other actions
    } catch (err) {
      // Handle errors
    }
  };

  return (
    <div>
      <div>{renderStep()}</div>
    </div>
  );
};

export default Onboarding;
