// src/components/GoogleAnalytics.js
import React from "react";
import { Helmet } from "react-helmet";


const GoogleAnalytics = () => {
  const googleAnalyticsSrc = process.env.REACT_APP_GOOGLE_ANALYTICS;
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  return (
    <Helmet>
      <script
        async
        src={googleAnalyticsSrc}
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleAnalyticsId}');
        `}
      </script>
    </Helmet>
  );
};  

export default GoogleAnalytics;
