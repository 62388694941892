import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import React, { useEffect } from "react";
import Error from "./components/error/Error";
import HomePageIndex from "./pages/home-page/Index";
import OnboardingIndex from "./pages/onboarding-flow/Index";
import Footer from "./components/footer/Footer";
import GoogleAnalytics from "./GoogleAnalytics/GoogleAnalytics";
import PrivacyIndex from "./pages/privacy-policy/Index";
import MsmeParticipantIndex from "./pages/MsmeParticipants/Index";
import OnboardingMsmeIndex from "./pages/onboarding-msme-flow/Index";

const TrackPageView = () => {
  const location = useLocation();
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useEffect(() => {
    if (window.gtag && googleAnalyticsId) {
      window.gtag("config", googleAnalyticsId, {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, googleAnalyticsId]);

  return null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <GoogleAnalytics />
      <TrackPageView />
      <ScrollToTop />
      <Routes>
        <Route index element={<HomePageIndex />} />
        <Route path="registration-form" element={<OnboardingIndex />} />
        <Route path="registration-flow" element={<MsmeParticipantIndex />} />
        <Route
          path="msme-onboarding"
          element={<OnboardingMsmeIndex
             />}
        />
        <Route path="privacy" element={<PrivacyIndex />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
